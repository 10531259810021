import './App.css';
import { Container, Select, Grid, Dropdown, Header } from 'semantic-ui-react'
import Hashmask from './components/Hashmask';
import SelectFields from './components/SelectFields';
import { Menu } from 'semantic-ui-react'
import Search from './components/Search';

import { Input } from 'semantic-ui-react'
import useFakeData from './hooks/useFakeData';
import { AppContext } from './libs/contextLib';
import Artblock from './components/Artblock';
import { useState } from 'react';

const InputExampleFocus = ({ style }) => <Input focus placeholder='Filter...' style={style} />


function MenuExampleBasic() {


  let handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  // const { activeItem } = this.state
  let activeItem = 'editorials'

  return (

    <Menu>
      <Menu.Item
        name='editorials'
        active={activeItem === 'editorials'}
        onClick={this.handleItemClick}
      >
        Editorials
        </Menu.Item>

      <Menu.Item
        name='reviews'
        active={activeItem === 'reviews'}
        onClick={this.handleItemClick}
      >
        Reviews
        </Menu.Item>

      <Menu.Item
        name='upcomingEvents'
        active={activeItem === 'upcomingEvents'}
        onClick={this.handleItemClick}
      >
        Upcoming Events
        </Menu.Item>
    </Menu>
  )
}


let DataModel = {

  'countries': {
    'af': 'Vertical Lines',
    'ax': 'Aland Island'
  },
  'category 2': {
    'sub 2 2': 'w'
  },
  'mask': {
    'animal': {
      'fox 1': 'Fox blue',
      'fox 2': 'Fox red'
    },
    'abstract': {
      'abs 1': 'bla',
      'abs 2': 'blub'
    }
  }

}

let tokenData = {
  126: {
    "tokenId": 126,
    "color": "orange",
    "colorShift": "complementary",
    "type": "omega",
    "panels": "unfolded",
    "operation": "nominal"
  },
  416: {
    "tokenId": 416,
    "color": "dodgerBlue",
    "colorShift": "monochromatic",
    "type": "alpha",
    "panels": "unfolded",
    "operation": "nominal"
  }
}


const countryOptions = [
  { key: 'af', value: 'af', text: 'Afghanistan' },
  { key: 'ax', value: 'ax', text: 'Aland Islands' },
  { key: 'al', value: 'al', text: 'Albania' },
  { key: 'dz', value: 'dz', text: 'Algeria' },
  { key: 'as', value: 'as', text: 'American Samoa' },
  { key: 'ad', value: 'ad', text: 'Andorra' },
  { key: 'ao', value: 'ao', text: 'Angola' },
  { key: 'ai', value: 'ai', text: 'Anguilla' },
  { key: 'ag', value: 'ag', text: 'Antigua' },
  { key: 'ar', value: 'ar', text: 'Argentina' },
  { key: 'am', value: 'am', text: 'Armenia' },
  { key: 'aw', value: 'aw', text: 'Aruba' },
  { key: 'au', value: 'au', text: 'Australia' },
  { key: 'at', value: 'at', text: 'Austria' },
  { key: 'az', value: 'az', text: 'Azerbaijan' },
  { key: 'bs', value: 'bs', text: 'Bahamas' },
  { key: 'bh', value: 'bh', text: 'Bahrain' },
  { key: 'bd', value: 'bd', text: 'Bangladesh' },
  { key: 'bb', value: 'bb', text: 'Barbados' },
  { key: 'by', value: 'by', text: 'Belarus' },
  { key: 'be', value: 'be', text: 'Belgium' },
  { key: 'bz', value: 'bz', text: 'Belize' },
  { key: 'bj', value: 'bj', text: 'Benin' },
]


function isObject(el) {
  return typeof el === 'object'
}

function App() {


  let { data } = useFakeData()

  const [project, setProject] = useState({
    name: 'Color Study',
    author: 'Jeff Davis',
    description: 'Exploring the interaction of four parent colors'
  })

  const [sortOption, setSortOption] = useState([])


  function handleSort() { }
  let sortOptions = []


  return (
    <Container className="App" >

      <AppContext.Provider value={{
        data
      }} >

        <div className="Navbar" style={{ display: 'flex', alignItems: 'center' }}>
          <Header as='h1'>Art Blocks Explorer</Header>
          <Search style={{ marginLeft: 'auto' }}/>
        </div>

        <Header as='h2'>{project.name} - {project.author}</Header>
        <Header as='h3'>{project.description}</Header>

        <div style={{ padding: '1rem', alignItems: 'center', display: 'flex' }}>
          <div style={{ marginLeft: 'auto', padding: '.5rem' }}>
            <InputExampleFocus style={{ marginRight: '1rem' }} />
            <Dropdown placeholder='Sort by' options={sortOptions} style={{ border: '1px solid black', padding: '.6rem' }} onChange={handleSort} />
          </div>
        </div>
        <div style={{ display: 'flex'}}>
          <div style={{ display: 'flex' }}>
            <SelectFields data={DataModel} />
          </div>
          <div>

            <Grid padded relaxed>
              {
                Array.from(Array(100).keys()).map(k =>
                  <Artblock key={k} tokenId={k} />
                )
              }
            </Grid>
          </div>
        </div>

      </AppContext.Provider>

    </Container>
  );
}

export default App;
