import React, { useState } from 'react'
import { Select } from 'semantic-ui-react'

export default function SelectFields({ data }) {

  const [config, setConfig] = useState({})


  function handleChange() {
    console.log('handle handle')
    setConfig({
      foo: 'bar'
    })
  }

  const main = Object.keys(data)

  return (
    <div style={{ padding: '1rem' }}>
    {
    main.map(mainCat => {

      let subcategories = Object.keys(data[mainCat])
      let subOptions = subcategories.map((subCat, idx) => {
        return {
          key: subCat,
          value: subCat,
          text: data[mainCat][subCat]
        }
      })

      return <Select placeholder='Select your filter' options={subOptions} style={{ marginTop: '.5rem'}} onChange={handleChange} />
    })
    }
    </div>
  )
}
