import faker from 'faker'
import _ from 'lodash'

const source = _.times(5, () => ({
  title: faker.company.companyName(),
  description: faker.company.catchPhrase(),
  image: faker.internet.avatar(),
  price: faker.finance.amount(0, 100, 2, '$'),
}))

export default function useFakeData() {
  return {
    data: source
  }
}