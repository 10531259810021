import React from 'react'
import { Card, Icon, Image } from 'semantic-ui-react'

function tokenidToArtblockId(tokenId, projectId = 16) {
  return `${projectId}000${ tokenId < 10 ? `00${tokenId}` : (tokenId < 100 ? `0${tokenId}` : tokenId) }`
}

export default function Artblock({ tokenId }) {
  return (
    <Card style={{ margin: '2rem' }}>
    <Image src={`https://api.artblocks.io/image/${tokenidToArtblockId(tokenId)}`} wrapped ui={false} />
    <Card.Content>
      <Card.Header>#{tokenId}</Card.Header>
      <Card.Meta>
        <span className='date'>Created 2021</span>
      </Card.Meta>
      <Card.Description>
        Trait 1
      </Card.Description>
    </Card.Content>
    <Card.Content extra>

    </Card.Content>
  </Card> 
  )
}
